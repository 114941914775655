import headerList from "@/components/common/header-list.vue";
import paginationMixin from "@/Mixins/paginationMixin";
import PaginationComponent from "../../../Global-Component/pagination.vue";
import { _ } from "vue-underscore";

export default {
  data: () => ({
    billingCountryList: [],
    selected: "",
    selectedId: 0,
    selectedRows: [],
    showLoader: false,
    searchText: "",
    selectedFile: [],
    paginate: {
      page_count: 1,
      selected: 1,
      per_page: 50,
      current_page: 1,
      last_page: 0,
    },
    recordTakeOptions: [25, 50, 100, 200],
  }),

  mixins: [paginationMixin],
  components: { "header-list": headerList, PaginationComponent },

  computed: {
    selectAllList() {
      if (this.billingCountryList.length > 0) {
        return (
          _.where(this.billingCountryList, { isSelected: true }).length ===
          this.billingCountryList.length
        );
      }
      return false;
    },
    hasbillingCountryShowPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "List" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "List" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "List" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasbillingCountryAddPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Store" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Store" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Store" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasbillingCountryDeletePermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Delete" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Delete" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Delete" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasbillingCountryUpdatePermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Update" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Update" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Update" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
  },
  methods: {
    onFileChange(e) {
      // Retrieve the selected files from the event object
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      // Clear the existing selected files array
      this.selectedFile = [];
      // Iterate through each file and add it to the selectedFile array
      Array.prototype.forEach.call(files, (item) => {
        this.selectedFile.push(item);
      });
    },

    exportBillingCountryState() {
      // Show loader
      this.$store.state.isLoaderShow = true;
      // Make a GET request to retrieve billing countries
      this.axios.get("/export/billing-countries").then((response) => {
        // Create a download link element
        let a = document.createElement("a");
        // Set the href and download attributes
        a.href = response.data.data.file;
        a.download = response.data.data.name;
        // Trigger a click event to initiate the download
        a.click();
      });
    },

    updateBillingCountryStatus(billingCountry, event) {
      let _vm = this;
      let _msg =
        billingCountry.is_active == true
          ? "Are you sure you want to Deactive this billingCountry?"
          : "Are you sure you want to Active this billingCountry?";
      let _is_active = billingCountry.is_active == true ? 1 : 0;
      // Display confirmation dialog
      this.$dialog
        .confirm(_msg)
        .then(function () {
          // Send a POST request to update billing country status
          _vm.axios
            .post(
              "/billing-countries-update-status/id?id=" +
                billingCountry.id +
                "&is_active=" +
                _is_active
            )
            .then(function () {
              // Refresh the data after successful update
              _vm.getAll();
            })
            .catch(function () {
              // Handle errors and revert the checkbox state
              document.getElementById(
                "billingCountry-" + billingCountry.id
              ).checked = billingCountry.is_active;
            });
        })
        .catch(function () {
          // User canceled the action, revert the checkbox state
          document.getElementById(
            "billingCountry-" + billingCountry.id
          ).checked = billingCountry.is_active;
        });
    },

    uploadBillingCountryState() {
      // Check if a file is selected
      if (this.selectedFile && this.selectedFile.length > 0) {
        let _vm = this;
        let fd = new FormData();
        fd.append("xlsx", this.selectedFile[0]);
        // Send a POST request to import billing countries
        this.axios.post("/import/billing-countries", fd).then(() => {
          _vm.selectedFile = [];
          document.getElementById("file-input").value = null;
          _vm.getAll();
        });
      } else {
        // Display an error if no file is selected
        this.$toast.error("Please select a file first!");
      }
    },

    checkUncheckedList(event) {
      this.billingCountryList.forEach((element) => {
        element.isSelected = event.target.checked;
      });
    },
    deleteRecord(id) {
      let _vm = this;
      let ids = [];
      // Check if a specific ID is selected
      if (this.selectedId > 0) {
        ids = [this.selectedId];
      } else {
        // Loop through billingCountryList to find selected elements
        this.billingCountryList.forEach((element) => {
          if (element.isSelected == true) {
            ids.push(element.id);
          }
        });
      }
      // Perform the deletion request
      _vm.axios
        .post("/billing-countries-deletes", { ids: ids })
        .then(function () {
          // Request successful, update data and reset selectedId
          _vm.getAll();
          _vm.selectedId = 0;
        })
        .catch(function () {
          // Request failed
        });
    },

    search() {
      this.getAll();
    },
    getQueryString() {
      let queryString =
        "?page=" +
        this.paginate.current_page +
        "&take=" +
        this.paginate.per_page;
      queryString +=
        this.searchText.length > 0 ? "&search=" + this.searchText : "";
      return queryString;
    },
    getNextPage(page) {
      this.paginate.current_page = page;
      this.getAll();
    },
    updateRecordTake(newRecordTake) {
      this.paginate.per_page = newRecordTake;
      this.getAll();
    },
    getPageCount(dividend, divider) {
      let page = Math.floor(dividend / divider);
      return dividend % divider === 0 ? page : page + 1;
    },
    getAll() {
      let _vm = this;
      let queryString = this.getQueryString();
      _vm.showLoader = true;
      // Send a GET request to retrieve billing countries
      this.axios
        .get("/billing-countries" + queryString)
        .then(function (response) {
          _vm.showLoader = false;
          _vm.billingCountryList = response.data.data.data;
          _vm.paginate.last_page = response.data.data.last_page;
          _vm.paginate.page_count = _vm.getPageCount(
            response.data.data.total,
            response.data.data.per_page
          );
        })
        .catch(function () {
          // Handle any errors here
        });
    },

    handleCheck(e) {
      // Check if the target element is customCheck-1
      if (e.target.id === "customCheck-1") {
        if (e.target.checked) {
          // If customCheck-1 is checked, select all rows and update isSelected property
          this.selectedRows = this.billingCountryList.map(
            (item) => "checkbox" + item.id
          );
          this.billingCountryList.forEach((element) => {
            element.isSelected = e.target.checked;
          });
        } else {
          // If customCheck-1 is unchecked, deselect all rows and update isSelected property
          this.selectedRows = [];
          this.billingCountryList.forEach((element) => {
            element.isSelected = false;
          });
        }
      } else {
        if (e.target.checked) {
          // Add the selected row to the selectedRows array
          this.selectedRows.push(e.target.id);
        } else {
          // Remove the deselected row from the selectedRows array
          this.selectedRows = this.selectedRows.filter((item) => {
            if (item !== e.target.id) {
              return item;
            }
          });
        }
      }
      // You can add more comments here if needed
    },
  },
  mounted() {
    this.getAll();
  },
};
